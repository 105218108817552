import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../layout"
import { get, find } from "lodash"
import ReactHtmlParser from "react-html-parser"
import SEO from "../seo"
import AppWrapper from "../../styles/AppWrapper"
import H1 from "../H1"
import { colors } from "../../styles/colors"
import { device } from "../../styles/device"
import { fontFamily } from "../../styles/fonts/fontFamily"
import Video from "./Video"
import { useLocation } from "@reach/router"
import ShareButtons from "../Share/ShareButtons"

const BlogPost = props => {
    const blogPostQuery = useStaticQuery(
        graphql`
            query {
                allBlogPostsJson {
                    edges {
                        node {
                            blogPosts {
                                id
                                title
                                article
                                image {
                                    src {
                                        childImageSharp {
                                            fluid(maxWidth: 900, quality: 100) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                                callout
                                slug
                                videoSrcURL
                                videoTitle
                            }
                        }
                    }
                }
            }
        `
    )

    const slug = get(props, "pageContext.slug", "")
    const posts = get(
        blogPostQuery,
        "allBlogPostsJson.edges[0].node.blogPosts",
        []
    )
    const post = find(posts, blogPosts => blogPosts.slug === slug)
    console.log("post", post)
    console.log("posts", posts)

    const location = useLocation()

    const title = `Read ${post.title} `
    const url = location.href
    const twitterHandle = "johnnieaiello"

    return (
        <Layout>
            <SEO
                title={`${post.title} | Johnnie Aiello`}
                description={post.article}
                isBlogPost
                image={post.image.src.childImageSharp.fluid}
            />
            <section>
                <AppWrapper>
                    <BlogPostTitle>{ReactHtmlParser(post.title)}</BlogPostTitle>
                    {post.videoSrcURL && (
                        <Video
                            videoSrcURL={post.videoSrcURL}
                            videoTitle={post.videoTitle}
                        />
                    )}
                    <ParagraphContainer>
                        <ParagraphDiv>
                            {ReactHtmlParser(post.article)}
                        </ParagraphDiv>
                        <ShareButtons
                            title={title}
                            url={url}
                            twitterHandle={twitterHandle}
                            css={css`
                                @media ${device.tablet} {
                                    margin-bottom: 100px;
                                }
                            `}
                        />
                    </ParagraphContainer>
                </AppWrapper>
            </section>
        </Layout>
    )
}

export default BlogPost

const BlogPostTitle = styled(H1)`
    margin: 150px 0 50px;

    @media ${device.tablet} {
        margin: 150px 0 50px;
    }
`

const ParagraphContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const ParagraphDiv = styled.div`
    position: relative;
    z-index: 2;
    margin-bottom: 20px;

    p {
        max-width: 65ch;
        font-size: 18px;
        color: ${colors.black};
        line-height: 1.8;
        z-index: 9;
        font-family: ${fontFamily.manrope};
        font-weight: 500;
        padding: 15px 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 0;

        &:first-of-type {
            padding-top: 0;
        }
    }

    a {
        text-decoration: none;
        color: ${colors.purple};
    }
`
