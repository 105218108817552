import React from "react"
import styled from "@emotion/styled"
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon,
    EmailShareButton,
    EmailIcon,
    PocketShareButton,
    PocketIcon
} from "react-share"
import { device } from "../../styles/device"

const ShareButtons = ({ title, url, twitterHandle }) => {
    return (
        <SocialShareContainer>
            <FacebookShareButton url={url}>
                <FacebookIcon size={30} />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle}>
                <TwitterIcon size={30} />
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
                <LinkedinIcon size={30} />
            </LinkedinShareButton>

            <RedditShareButton url={url} title={title}>
                <RedditIcon size={30} />
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={30} />
            </WhatsappShareButton>

            <EmailShareButton url={url} title={title}>
                <EmailIcon size={30} />
            </EmailShareButton>

            <PocketShareButton url={url} title={title}>
                <PocketIcon size={30} />
            </PocketShareButton>
        </SocialShareContainer>
    )
}
export default ShareButtons

const SocialShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    .react-share__ShareButton {
        svg {
            width: 30px;
            height: 30px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        @media ${device.tablet} {
            margin-right: 10px;
        }
    }

    @media ${device.tablet} {
        max-width: 350px;
    }
`
