import React from "react"
import styled from "@emotion/styled"
import { device } from "../../styles/device"

const Video = ({ videoSrcURL, videoTitle }) => (
    <VideoContainer>
        <Frame
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
        />
    </VideoContainer>
)
export default Video

const VideoContainer = styled.div`
    width: 100%;
    padding-bottom: 4rem;
`

const Frame = styled.iframe`
    width: 732px;
    max-width: 100%;

    height: 240px;

    @media ${device.tablet} {
        height: 350px;
    }
`
